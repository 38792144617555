// @Library
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment'; // deprecated

// @Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useTranslation } from 'react-i18next';

// @Models
import { IShipmentFormEdit, IServicesTab, IShipmentEdit, IClientTabForm } from '../../../models';

// @Utils
import { axiosEstimates } from '../../../utils/api';
import CustomSwalAlert from '../../../utils/CustomSwalAlert';
import { addShipmentAdapter, editShipmentAdapter } from './utils/add-shipment.adapter';

interface IProps {
  data: IServicesTab;
  customer: IClientTabForm;
  budgetStatus: string;
  idBudget: number;
  tabChange: (newValue: number) => void;
  budgetHaveBill: boolean;
}

export function useServiceStep({ data, idBudget, budgetStatus, customer, tabChange, budgetHaveBill }: IProps) {
  const [renderForm, setRenderForm] = useState(false);
  const [service, setService] = useState<IShipmentFormEdit | undefined>();
  const [shipments, setShipments] = useState<IShipmentEdit[]>(data.shipments);

  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  const { user } = useSelector((state: RootState) => state);

  const handleRenderForm = (service?: IShipmentEdit) => {
    const shipment = editShipmentAdapter(service);
    setService(shipment);
    setRenderForm(prev => !prev);
  };

  const handleAddService = (service: IShipmentFormEdit) => {
    const shipment = addShipmentAdapter(Object.assign(service, { id: shipments.length + 1 }));
    if (shipment) {
      const newShipment = Object.assign(shipment, { id: shipments.length + 1 });
      setShipments([...shipments, newShipment]);
      setRenderForm(false);
    }
  };

  const handleEditService = (service: IShipmentFormEdit) => {
    const editShipment = addShipmentAdapter(service);
    if (editShipment) {
      const newShipments = shipments.map(shipment => {
        if (shipment.id === editShipment.id) {
          return editShipment;
        }
        return shipment;
      });

      setShipments(newShipments);
      setRenderForm(false);
    }
  };

  const handleDeleteService = (id: number) => {
    const oldShipments = shipments.filter(shipment => shipment.id !== id);
    const newShipments = oldShipments.map((shipment, index) => {
      return Object.assign(shipment, { id: index + 1 });
    });
    setShipments(newShipments);
  };

  const handleEditRequest = async (editShipments: IShipmentEdit[]) => {
    const toastId = toast.loading(t('toast.saved', { ns: 'common' }));

    try {
      await axiosEstimates.put<{ data: { status: string } }>('budget/' + idBudget, {
        shipments: editShipments,
        approved: true,
        hasChanges: data.oldCustomerCommission !== data.customerCommission,
      });

      toast.dismiss(toastId);
      navigate('/requests');
      toast.success(t('toast.success', { ns: 'common' }));
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('toast.error', { ns: 'common' }));
    }
  };

  const handleEdit = () => {
    const editCb = async () => {
      const today = new Date();
      const country = user.user?.person?.country_alpha2code;
      const dateOfExpiration = customer.dateOfExpiration;

      const startDate = moment(today);
      const endDate = moment(dateOfExpiration);
      const diffDays = endDate.diff(startDate, 'days');

      let days = 0;

      if (diffDays <= 1) {
        CustomSwalAlert(`${t('errors.minExpDay')}`, `${t('budgetPage.edit.expDayMessage')}`, 'warning', true, () => {
          tabChange(1);
          return null;
        });

        days = 3;
      }

      const toastId = toast.loading(t('toast.saved', { ns: 'common' }));
      try {
        const res = await axiosEstimates.put<{ data: { status: string } }>('budget/' + idBudget, {
          shipments,
          approved: true,
          hasChanges: data.oldCustomerCommission !== data.customerCommission,
          country,
          days,
        });

        toast.dismiss(toastId);

        const status = res.data.data.status;
        const approved = status === 'active' && budgetStatus === 'pending';
        if (status === 'toBeConfirmed') {
          await axiosEstimates.post('/customer/send/budget/' + idBudget, {
            emails: [customer.customerEmail],
            country,
          });
        }
        if (approved) {
          Swal.fire({
            title: 'Se aprobo esta cotización',
            text: '¿Quieres enviar un correo al vendedor?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
          }).then(async result => {
            if (result.isConfirmed) {
              await axiosEstimates.post('reject-budget/' + idBudget, {
                approved,
              });
              toast.success(t('toast.success', { ns: 'common' }));
              navigate('/budgets');
            }
          });
        } else {
          toast.success(t('toast.success', { ns: 'common' }));
          setTimeout(() => {
            if (status === 'pending') navigate('/budgets');
            else navigate('/budgets/print/' + idBudget);
          }, 1500);
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error(t('toast.error', { ns: 'common' }));
      }
    };

    if (budgetHaveBill) {
      CustomSwalAlert(t('toast.warning'), t('budget.confirmEdit', { ns: 'validations' }), 'warning', true, editCb);
    } else editCb();
  };

  const handleReject = async () => {
    const toastId = toast.loading(t('toast.send', { ns: 'common' }));
    try {
      await axiosEstimates.post('reject-budget/' + idBudget, {});
      toast.dismiss(toastId);
      toast.success(t('toast.email', { ns: 'common' }));
      navigate('/budgets');
    } catch (error) {
      toast.dismiss(toastId);
      toast.error(t('toast.error', { ns: 'common' }));
    }
  };

  return {
    services: shipments,
    renderForm,
    handleRenderForm,
    handleAddService,
    handleEditService,
    handleDeleteService,
    subTotal: shipments.reduce((prev, serv) => prev + serv.subTotal, 0),
    ivatotal: shipments.reduce((prev, serv) => prev + serv.ivaTotal, 0),
    total: shipments.reduce((prev, serv) => prev + serv.total, 0),
    service,
    user,
    handleEdit,
    handleReject,
    handleEditRequest,
    budgetType: data.budgetType,
  };
}
